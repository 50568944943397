import React, { useState } from "react";
import PropTypes from "prop-types";
import GoogleCalendar from "./google/GoogleCalendar";
import CreateEvent from "./events/CreateEvent";
import EditEvent from "./events/EditEvent";
import ListEvents from "./events/ListEvents";
import Accordion from "react-bootstrap/Accordion";
import Container from "react-bootstrap/Container";
import Spinner from "react-bootstrap/Spinner";
import { useHistory, useLocation } from "react-router-dom";

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Calendar({
  googleUser,
  resources,
  me,
  events,
  doneLoadingFuture,
  doneLoadingPast,
  fetchEvents,
  impersonate,
}) {
  let query = useQuery();
  let history = useHistory();

  const [refreshId, setRefreshId] = useState(0);
  const [createEvent, showCreateEvent] = useState(query.get("create") != null);
  const [editEvent, showEditEvent] = useState(false);
  const [date, setDate] = useState(new Date());
  const [event, setEvent] = useState(null);

  const resourcesByEmail =
    resources &&
    resources["hydra:member"].reduce(function (map, resource) {
      map[resource.email] = resource;
      return map;
    }, {});

  if ((query.get("create") != null) !== createEvent) {
    showCreateEvent(query.get("create") != null);
  }

  let refreshCalendar = () => {
    setRefreshId(refreshId + 1);
  };

  let futureEvent = (event) => new Date(event.startDateTime) > new Date();
  let pastEvent = (event) => new Date(event.startDateTime) <= new Date();
  let sortAscending = (a, b) =>
    new Date(a.startDateTime) - new Date(b.startDateTime);
  let sortDescending = (a, b) =>
    new Date(b.startDateTime) - new Date(a.startDateTime);
  const futureEvents = events && events.filter(futureEvent).sort(sortAscending);
  const pastEvents = events && events.filter(pastEvent).sort(sortDescending);

  return (
    <>
      <Container fluid={true} className="p-4">
        {me && me.roles && me.roles.includes && !me.roles.includes("ROLE_PRACTITIONER") && (
          <>
            <div className="legend">
              <div>Legend:</div>
              <div>
                <div className="legend-box events">Events</div>
                <div className="legend-box practitioners">Practitioners</div>
                <div className="legend-box hatha">Hatha Equus</div>
              </div>
            </div>
            <GoogleCalendar
              refreshId={refreshId}
              date={date}
              // TODO: Make this a param.
              src="src=d2lsZHNvbmdyYW5jaC5jb21fazZkODU2Zmk4bnJ1YTFmaGJmN29vOWk0NDRAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=Y19qZ2pyN25sNjVtNDhldGx1anVqNnZyaW0wa0Bncm91cC5jYWxlbmRhci5nb29nbGUuY29t&src=d2lsZHNvbmdyYW5jaC5jb21fMG5lbjE1b21zM29wbWJudW5idHM4b25ram9AZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&color=%234285F4&color=%23616161&color=%23B39DDB"
            />
          </>
        )}
        {me && me.roles && me.roles.includes && me.roles.includes("ROLE_PRACTITIONER") && (
          <>
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  <i className="material-icons align-middle">calendar_today</i>
                  &nbsp;Wildsong Calendar
                </Accordion.Header>
                <Accordion.Body>
                  <div className="legend">
                    <div>Legend:</div>
                    <div>
                      <div className="legend-box events">Events</div>
                      <div className="legend-box practitioners">Practitioners</div>
                      <div className="legend-box hatha">Hatha Equus</div>
                    </div>
                  </div>
                  <GoogleCalendar
                    refreshId={refreshId}
                    date={date}
                    // TODO: Make this a param.
                    src="src=d2lsZHNvbmdyYW5jaC5jb21fazZkODU2Zmk4bnJ1YTFmaGJmN29vOWk0NDRAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=Y19qZ2pyN25sNjVtNDhldGx1anVqNnZyaW0wa0Bncm91cC5jYWxlbmRhci5nb29nbGUuY29t&src=d2lsZHNvbmdyYW5jaC5jb21fMG5lbjE1b21zM29wbWJudW5idHM4b25ram9AZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&color=%234285F4&color=%23616161&color=%23B39DDB"
                  />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <i className="material-icons align-middle">event</i>&nbsp;My
                  Upcoming Sessions (2 months) &nbsp;
                  {googleUser && !doneLoadingFuture && (
                    <div className="legend">
                      <Spinner animation="border" variant="primary" />
                    </div>
                  )}
                </Accordion.Header>
                <Accordion.Body>
                  {futureEvents && (
                    <ListEvents
                      events={futureEvents}
                      onEdit={(event) => {
                        setEvent(event);
                        showEditEvent(true);
                      }}
                      me={impersonate ? impersonate : me}
                      resourcesByEmail={resourcesByEmail}
                    />
                  )}
                  {googleUser && !doneLoadingFuture && (
                    <div className="legend">
                      <Spinner animation="border" variant="primary" />
                      &nbsp;loading...
                    </div>
                  )}
                  {doneLoadingFuture &&
                    (!futureEvents || futureEvents.length === 0) && (
                      <div className="legend">No Sessions Found</div>
                    )}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  <i className="material-icons align-middle">event_repeat</i>
                  &nbsp;My Past Sessions (1 month) &nbsp;
                  {googleUser && !doneLoadingPast && (
                    <div className="legend">
                      <Spinner animation="border" variant="primary" />
                    </div>
                  )}
                </Accordion.Header>
                <Accordion.Body>
                  {pastEvents && (
                    <ListEvents
                      events={pastEvents}
                      onEdit={(event) => {
                        setEvent(event);
                        showEditEvent(true);
                      }}
                      me={impersonate ? impersonate : me}
                      showSummary={true}
                      resourcesByEmail={resourcesByEmail}
                    />
                  )}
                  {googleUser && !doneLoadingPast && (
                    <div className="legend">
                      <Spinner animation="border" variant="primary" />
                      &nbsp;loading...
                    </div>
                  )}
                  {doneLoadingPast && (!pastEvents || pastEvents.length === 0) && (
                    <div className="legend">No Sessions Found</div>
                  )}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </>
        )}
      </Container>
      {me && (
        <>
          <CreateEvent
            googleUser={googleUser}
            me={me}
            resources={resources}
            show={createEvent}
            onHide={() => history.push("/")}
            onCreate={(event) => {
              refreshCalendar();
              fetchEvents();
            }}
            onDateChange={setDate}
            impersonate={impersonate}
          />
          {event && (
            <EditEvent
              googleUser={googleUser}
              resources={resources}
              resourcesByEmail={resourcesByEmail}
              me={me}
              event={event}
              show={editEvent}
              onHide={() => showEditEvent(false)}
              onEdit={(event) => {
                refreshCalendar();
                fetchEvents();
              }}
              onDelete={(eventId) => {
                refreshCalendar();
                fetchEvents();
              }}
              onDateChange={setDate}
              impersonate={impersonate}
            />
          )}
        </>
      )}
    </>
  );
}

Calendar.propTypes = {
  googleUser: PropTypes.object,
  resources: PropTypes.object,
  me: PropTypes.object,
  events: PropTypes.array,
  doneLoadingFuture: PropTypes.bool,
  doneLoadingPast: PropTypes.bool,
  fetchEvents: PropTypes.func,
  impersonate: PropTypes.object,
};

export default Calendar;
