import React, { useState, useReducer, useEffect } from "react";
import chroma from "chroma-js";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Spinner from "react-bootstrap/Spinner";
import makeAnimated from "react-select/animated";
import Select from "react-select";
import DatePicker from "react-datepicker";
import parse from "date-fns/parse";
import {
  addMinutes,
  addDays,
  addMonths,
  humanReadableDuration,
  millisToHours,
  newTime,
} from "../utils/dates";
import { ownerNames } from "../utils/horses";
import { eventCostTable, EVENT_SUMMARY_REGEX, getGroupSize, getSessionType } from "../utils/event";
import "react-datepicker/dist/react-datepicker.css";
import FormSelect from "react-bootstrap/esm/FormSelect";

let includesLocation = (resources) => {
  return (
    resources && resources.find((resource) => resource.resource["building"])
  );
};

let resourceColor = (resource) => {
  if (resource.responseStatus === "declined" || (resource.horse && !resource.horse.bookable)) {
    return "red";
  } else {
    return resource.horse || resource.generatedName.startsWith("(Horse)") ? "green" : "blue";
  }
};

function resourceOptions(resources) {
  var options = [];
  if (resources && resources["hydra:member"]) {
    resources["hydra:member"].forEach((resource) => {
      var groupLabel = resource["building"]
        ? resource["building"]
        : resource["type"];
      if (groupLabel) {
        var group = options.find((group) => group.label === groupLabel);
        if (!group) {
          group = {
            label: groupLabel,
            building: resource["building"],
            type: resource["type"],
            options: [],
          };
          options.push(group);
        }

        var resourceLabel = resource["name"];
        if (resource["type"] === "Horse") {
          resourceLabel += " (" + ownerNames(resource["horse"]) + ")";
        } else if (resource["capacity"]) {
          resourceLabel += " (" + resource["capacity"] + ")";
        }
        if (resource["type"] !== "Horse" || resource.horse.status === "current") {
          group.options.push({
            value: resource["email"],
            label: resourceLabel,
            resource: resource,
            color: resourceColor(resource),
          });  
        }
      }
    });

    options.sort((a, b) => {
      if (a.building && b.building) {
        return a.building.localeCompare(b.building);
      } else if (a.building) {
        return -1;
      } else if (b.building) {
        return 1;
      } else {
        return a.type.localeCompare(b.type);
      }
    });
  }
  return options;
}

function reducer(state, action) {
  switch (action.selection) {
    case "start":
      // Update the new start time.
      var newStartTime = action.time;
      newStartTime.setSeconds(0);
      newStartTime.setMilliseconds(0);

      // Update the end time to match current duration (if any).
      var newEndTime = state.endTime;
      if (state.startTime && state.endTime) {
        var duration = state.endTime.getTime() - state.startTime.getTime();
        newEndTime = new Date(newStartTime.getTime() + duration);
        newEndTime.setSeconds(0);
        newEndTime.setMilliseconds(0);
      }

      return {
        ...state,
        minEnd: addMinutes(newStartTime, 30),
        startTime: newStartTime,
        endTime: newEndTime,
      };
    case "end":
      // Update the new end time.
      newEndTime = action.time;
      newEndTime.setSeconds(0);
      newEndTime.setMilliseconds(0);

      // Update the max start time to only allow valid changes.
      var newMaxStart = state.maxStart;
      if (state.startTime) {
        duration = newEndTime.getTime() - state.startTime.getTime();
        newMaxStart = new Date(newTime(20, 0).getTime() - duration);
      } else {
        newMaxStart = addMinutes(newEndTime, -30);
      }

      return {
        ...state,
        duration: humanReadableDuration(duration),
        durationHours: millisToHours(duration),
        maxStart: newMaxStart,
        endTime: newEndTime,
      };
    default:
      throw new Error();
  }
}

function EventForm(props) {
  const [validated, setValidated] = useState(false);
  const [recurrence, setRecurrence] = useState(props.event && props.event.recurrence ? { value: props.event.recurrence[0] } : "");
  const [selectedResources, setSelectedResources] = useState(
    props.event
      ? props.event.resources.map((resource) => {
        if (props.resourcesByEmail && props.resourcesByEmail[resource.email]) {
          resource = { ...props.resourcesByEmail[resource.email], responseStatus: resource.responseStatus }
        }
        return {
          value: resource.email,
          label: resource.generatedName.split("-").pop(),
          resource: resource,
          color: resourceColor(resource),
        };
      })
      : []
  );
  const [eventDate, setEventDate] = useState(
    props.event ? new Date(props.event.startDateTime) : null
  );
  const initialDuration = props.event ? new Date(props.event.endDateTime) - new Date(props.event.startDateTime) : 0;
  const [times, updateTimes] = useReducer(reducer, {
    minStart: newTime(8, 0),
    maxStart: newTime(18, 30),
    minEnd: newTime(8, 30),
    maxEnd: newTime(19, 0),
    startTime: props.event ? new Date(props.event.startDateTime) : null,
    endTime: props.event ? new Date(props.event.endDateTime) : null,
    duration: props.event ? humanReadableDuration(initialDuration) : null,
    durationHours: props.event ? millisToHours(initialDuration) : null,
  });
  const [busyResources, setBusyResources] = useState({});
  const options = resourceOptions(props.resources);
  const recurrenceOptions = [
    { value: "", label: "Does not repeat" },
    { value: props.event && props.event.recurrence && props.event.recurrence.length === 1 ? props.event.recurrence[0] : "RRULE:FREQ=WEEKLY", label: "Weekly" }
  ];
  const isDisabled = props.saving || props.deleting || props.disableEditDelete;
  const alreadyBookedResources = {};
  if (props.event) {
    props.event.resources.forEach((resource) => {
      if (resource.responseStatus === "accepted") {
        alreadyBookedResources[resource.email] = true;
      }
    });
  }

  const selectStyles = {
    control: (styles) => ({ ...styles, backgroundColor: "white" }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: isDisabled
          ? null
          : isSelected
            ? data.color
            : isFocused
              ? color.alpha(0.1).css()
              : null,
        color: isDisabled
          ? "#ccc"
          : isSelected
            ? chroma.contrast(color, "white") > 2
              ? "white"
              : "black"
            : data.color,
        cursor: isDisabled ? "not-allowed" : "default",

        ":active": {
          ...styles[":active"],
          backgroundColor:
            !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
        },
      };
    },
    multiValue: (styles, { data }) => {
      const color = chroma(data.color);
      if (data.resource.responseStatus === "declined" || (busyResources[data.value] && !alreadyBookedResources[data.value])) {
        return {
          ...styles,
          backgroundColor: color.alpha(0.1).css(),
          textDecoration: "line-through",
        };
      } else {
        return {
          ...styles,
          backgroundColor: color.alpha(0.1).css(),
        };
      }
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: data.color,
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: data.color,
      ":hover": {
        backgroundColor: data.color,
        color: "white",
      },
    }),
  };

  useEffect(() => {
    var resources = props.resources;
    if (
      eventDate &&
      times.startTime &&
      times.endTime &&
      props.resources &&
      resources["hydra:member"]
    ) {
      var items = [];
      resources["hydra:member"].forEach((resource) => {
        items.push({
          id: resource["email"],
        });
      });

      var startDateTime = new Date(
        eventDate.getFullYear(),
        eventDate.getMonth(),
        eventDate.getDate(),
        times.startTime.getHours(),
        times.startTime.getMinutes(),
        0,
        0
      );
      var endDateTime = new Date(
        eventDate.getFullYear(),
        eventDate.getMonth(),
        eventDate.getDate(),
        times.endTime.getHours(),
        times.endTime.getMinutes(),
        0,
        0
      );

      var token = window.gapi.client.getToken();
      window.gapi.client.setToken(null);
      window.gapi.client.calendar.freebusy
        .query({
          items: items,
          calendarExpansionMax: 50,
          timeMin: startDateTime.toISOString(),
          timeMax: endDateTime.toISOString(),
          timeZone: "America/Denver",
        })
        .then(function (response) {
          var busyResources = {};
          for (var calendar in response.result.calendars) {
            var busyTimes = response.result.calendars[calendar].busy;
            if (busyTimes.length) {
              busyResources[calendar] = true;
            }
          }
          setBusyResources(busyResources);
        });
      window.gapi.client.setToken(token);
    }
  }, [props.resources, eventDate, times]);


  const eventSummary = props.event && props.event.summary;
  const summaryMatch = eventSummary && eventSummary.match(EVENT_SUMMARY_REGEX);
  const defaultSummary = summaryMatch && summaryMatch.groups && summaryMatch.groups.summary !== undefined ?
    summaryMatch.groups.summary :
    (summaryMatch && summaryMatch.groups && (summaryMatch.groups.type !== undefined)) ? '' : props.event && props.event.summary;
  const [sessionType, setSessionType] = useState(getSessionType(eventSummary));
  const [groupSize, setGroupSize] = useState(getGroupSize(eventSummary));

  let titlePrefix = () => {
    if (sessionType === "Practitioner Group") {
      return "Practitioner Group of " + groupSize;
    }
    return sessionType;
  }

  let titlePostfix = () => {
    return ' (' + (props.impersonate ? props.impersonate.firstName || '' : props.me.firstName) + ')';
  }

  let fullSummary = (summary) => {
    return titlePrefix() + (summary.length > 0 ? ' - ' : '') + summary + titlePostfix();
  }

  let eventStateFromForm = (form) => {
    var startDateTime = parse(
      form.date.value + " " + form.startTime.value,
      "MM/dd/yyyy h:mm aa",
      new Date()
    );
    var endDateTime = parse(
      form.date.value + " " + form.endTime.value,
      "MM/dd/yyyy h:mm aa",
      new Date()
    );

    return {
      id: props.event ? props.event.id : "unknown",
      recurringEventId: props.event ? props.event.recurringEventId : "",
      recurrence: recurrence.value ? [recurrence.value] : [],
      summary: fullSummary(form.summary[1].value),
      startDateTime: startDateTime,
      endDateTime: endDateTime,
      resources: selectedResources
        ? selectedResources.map((item) => item.resource)
        : [],
      description: form.description.value,
    };
  }

  let handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    if (form.checkValidity()) {
      props.onSubmit(eventStateFromForm(form));
    }
    setValidated(true);
  };

  let isAllowedDate = (date) => {
    // Do not allow booking events on Sundays!
    return date.getDay() !== 0;
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Row>
        <Form.Group as={Col} className="mb-3" md="12" controlId="summary">
          <Form.Label>Summary</Form.Label>
          <InputGroup>
            <FormSelect
              required
              value={sessionType}
              onChange={(e) => setSessionType(e.target.value)}
              disabled={isDisabled}
            >
              <option key={'1'} value={''}>Session Type ...</option>
              <option key={'2'} value={'Practitioner Session'}>Individual Session</option>
              <option key={'3'} value={'Practitioner Group'}>Group Session</option>
              <option key={'4'} value={'Additional Resources'}>Addtional Resources</option>
            </FormSelect>
            <Form.Control
              type="text"
              placeholder="sub-title (optional)"
              disabled={isDisabled}
              defaultValue={defaultSummary}
            />
            <InputGroup.Text>{titlePostfix()}</InputGroup.Text>
          </InputGroup>
        </Form.Group>
      </Row>
      {sessionType === "Practitioner Group" &&
        <Row>
          <Form.Group as={Col} className="mb-3" md="6" controlId="group">
            <Form.Label>Group Details</Form.Label>
            <FormSelect
              required
              value={groupSize}
              onChange={(e) => setGroupSize(e.target.value)}
              disabled={isDisabled}
            >
              <option key={'1'} value={''}>Group Size ...</option>
              <option key={'2'} value={3}>3</option>
              <option key={'3'} value={4}>4</option>
              <option key={'4'} value={5}>5</option>
              <option key={'5'} value={6}>6</option>
              <option key={'6'} value={7}>7</option>
              <option key={'7'} value={8}>8</option>
              <option key={'8'} value={9}>9</option>
            </FormSelect>
          </Form.Group>
        </Row>}
      <Row>
        <Form.Group as={Col} className="mb-3" md="3" controlId="date">
          <Form.Label>Date</Form.Label>
          <div>
            <DatePicker
              required
              autoComplete="off"
              id="date"
              className="form-control"
              selected={eventDate}
              onChange={(date) => {
                setEventDate(date);
                props.onDateChange && props.onDateChange(date);
              }}
              minDate={addDays(new Date(), 1)}
              maxDate={addMonths(new Date(), 2)}
              filterDate={isAllowedDate}
              placeholderText="Session date"
              disabled={isDisabled || props.disableEditDateTime}
              dateFormat="MM/dd/yyyy"
            />
          </div>
        </Form.Group>
        <Form.Group as={Col} className="mb-3" md="3" controlId="recurrence">
            <>
              <Form.Label>Recurrence</Form.Label>
              <div>
                <Select
                  inputId="recurrence"
                  components={makeAnimated()}
                  placeholder="Does not repeat"
                  isDisabled={isDisabled || props.disableEditDateTime}
                  options={recurrenceOptions}
                  defaultValue={recurrenceOptions.filter((option) => option.value === recurrence.value)}
                  onChange={setRecurrence}
                />
              </div>
            </>
        </Form.Group>
        <Form.Group as={Col} className="mb-3" md="3" controlId="startTime">
          <Form.Label>Start Time</Form.Label>
          <div>
            <DatePicker
              required
              autoComplete="off"
              id="startTime"
              className="form-control"
              selected={times.startTime}
              onChange={(time) => {
                updateTimes({ selection: "start", time: time });
              }}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={30}
              timeCaption=""
              dateFormat="h:mm aa"
              minTime={times.minStart}
              maxTime={times.maxStart}
              disabled={isDisabled || props.disableEditDateTime}
            />
          </div>
        </Form.Group>
        <Form.Group as={Col} className="mb-3" md="3" controlId="endTime">
          <Form.Label>End Time {times.duration}</Form.Label>
          <div>
            <DatePicker
              required
              autoComplete="off"
              id="endTime"
              className="form-control"
              selected={times.endTime}
              onChange={(time) => updateTimes({ selection: "end", time: time })}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={30}
              timeCaption=""
              dateFormat="h:mm aa"
              minTime={times.minEnd}
              maxTime={times.maxEnd}
              disabled={isDisabled || props.disableEditDateTime}
            />
          </div>
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} className="mb-3" md="12" controlId="resources">
          <Form.Label>Resources</Form.Label>
          <Select
            inputId="resources"
            closeMenuOnSelect={false}
            components={makeAnimated()}
            isMulti
            isSearchable
            placeholder={
              options.length === 0
                ? "Loading..."
                : "Select optional resources..."
            }
            isLoading={options.length === 0}
            isDisabled={options.length === 0 || isDisabled}
            isOptionDisabled={(option) =>
              busyResources[option.resource["email"]] &&
              !alreadyBookedResources[option.resource["email"]]
            }
            defaultValue={selectedResources}
            onChange={setSelectedResources}
            options={options}
            styles={selectStyles}
          />
          {!includesLocation(selectedResources) > 0 && (
            <Form.Text>
              If no location is selected, it will default to a "Ranch Walkabout"
            </Form.Text>
          )}
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} className="mb-3" md="12" controlId="description">
          <Form.Label>Details</Form.Label>
          <Form.Control
            as="textarea"
            rows="3"
            placeholder="Session details (optional)"
            disabled={isDisabled}
            defaultValue={props.event ? props.event.description : ""}
          />
        </Form.Group>
      </Row>
      <Row>
        <div>
          {eventCostTable(
            (sessionType === "Additional Resources" && -1) || (sessionType === "Practitioner Group" && groupSize),
            selectedResources,
            times.durationHours,
            props.impersonate ? props.impersonate : props.me)}
        </div>
      </Row>
      {props.saving && (
        <Button disabled className="float-end ms-2" variant="primary">
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
          &nbsp; Saving...
        </Button>
      )}
      {!props.saving && props.onSubmit && !props.disableEditDelete && (
        <Button
          disabled={isDisabled}
          className="float-end ms-2"
          variant="primary"
          type="submit"
        >
          Save
        </Button>
      )}
      {props.deleting && (
        <Button disabled className="float-end ms-2" variant="secondary">
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
          &nbsp; Deleting...
        </Button>
      )}
      {!props.disableEditDelete &&
        !props.deleting &&
        props.onDelete &&
        props.event &&
        props.event.id && (
          <Button
            disabled={props.deleting || props.saving}
            className="float-end ms-2"
            variant="secondary"
            onClick={() => props.onDelete(props.event.id)}
          >
            Delete
          </Button>
        )}
    </Form>
  );
}

EventForm.propTypes = {
  resources: PropTypes.object,
  resourcesByEmail: PropTypes.object,
  me: PropTypes.object,
  onSubmit: PropTypes.func,
  saving: PropTypes.bool,
  onDelete: PropTypes.func,
  deleting: PropTypes.bool,
  disableEditDateTime: PropTypes.bool,
  disableEditDelete: PropTypes.bool,
  onDateChange: PropTypes.func,
  impersonate: PropTypes.object,
};

export default EventForm;
