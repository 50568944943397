import React from "react";
import PropTypes from "prop-types";
import format from "date-fns/format";
import Table from "react-bootstrap/esm/Table";
import { diffDays } from "../utils/dates";
import { eventFees, eventsLineItemsByMonth, eventMonthSummaryTable } from "../utils/event";

function ListRow({ event, onEdit, me, resourcesByEmail }) {
  // Event cannot be edited if more than a week old.
  let disableEditDelete =
    diffDays(new Date(event.startDateTime), new Date()) > 7;

  let hasDeclinedResource = !event.resources.every((resource) => resource.responseStatus === "accepted");

  return (
    <tr>
      <td style={hasDeclinedResource ? { color: "red" } : {}}>
        {event["summary"].replace(/^(Practitioner )/, "").trunc(50)}
      </td>
      <td className="hide-1600">{event["description"].trunc(100)}</td>
      <td className="hide-1000">
        {event.resources.map((resource) => {
          if (!resource.name) return "";
          return <div style={resource.responseStatus !== "accepted" ? { textDecoration: "line-through", color: "red" } : {}}>{resource.name}</div>;
        })}
      </td>
      <td className="hide-600">
        {format(new Date(event["startDateTime"]), "yyyy/M/d (eee)")}
        {event && event.recurrence && event.recurrence.length > 0 && <i className="material-icons align-middle">repeat</i>}
      </td>
      <td className="show-600">
        {format(new Date(event["startDateTime"]), "M/d")}
        {event && event.recurrence && event.recurrence.length > 0 && <i className="material-icons align-middle">repeat</i>}
      </td>
      <td className="hide-600">
        {format(new Date(event["startDateTime"]), "h:mm aa") +
          " - " +
          format(new Date(event["endDateTime"]), "h:mm aa")}
      </td>
      <td className="hide-800">
        ${eventFees(event, me, resourcesByEmail).toFixed(2)}
      </td>
      {onEdit && (
        <td>
          <button
            className="btn btn-outline-primary pull-right"
            onClick={() => onEdit(event)}
          >
            {(disableEditDelete && (
              <>
                <i className="material-icons align-middle">event_note</i>
                <span className="align-middle ps-1">View</span>
              </>
            )) || (
                <>
                  <i className="material-icons align-middle">edit</i>
                  <span className="align-middle ps-1">Edit</span>
                </>
              )}
          </button>
        </td>
      )}
    </tr>
  );
}

function EventsCostSummary({ events, me, resourcesByEmail }) {
  let months = eventsLineItemsByMonth(events, me, resourcesByEmail);
  return Object.keys(months).map((month) => {
    return eventMonthSummaryTable(month, months[month])
  });
}

function ListEvents({ events, onEdit, me, resourcesByEmail, showSummary }) {
  // Column titles, can sort by each of these
  const SUMMARY = "Title";
  const DESCRIPTION = "Description";
  const RESOURCES = "Resources";
  const DATE = "Date";
  const TIME = "Time";
  const FEES = "Fees";

  const columnHeader = (title, className) => {
    return <th className={className}>{title}</th>;
  };

  return (
    <>
      {events.length > 0 && (
        <>
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                {columnHeader(SUMMARY)}
                {columnHeader(DESCRIPTION, "hide-1600")}
                {columnHeader(RESOURCES, "hide-1000")}
                {columnHeader(DATE)}
                {columnHeader(TIME, "hide-600")}
                {columnHeader(FEES, "hide-800")}
                {onEdit && <th />}
              </tr>
            </thead>
            <tbody>
              {events.map((event) => {
                return (
                  <ListRow key={event["@id"]} event={event} onEdit={onEdit} me={me} resourcesByEmail={resourcesByEmail} />
                );
              })}
            </tbody>
          </Table>
          {showSummary &&
            <EventsCostSummary events={events} me={me} resourcesByEmail={resourcesByEmail} />}
        </>
      )}
    </>
  );
}

ListEvents.propTypes = {
  events: PropTypes.array.isRequired,
  onEdit: PropTypes.func,
  resourcesByEmail: PropTypes.object,
  showSummary: PropTypes.bool,
  me: PropTypes.object,
};

export default ListEvents;
