import React from "react";
import PropTypes from "prop-types";
import "./Calendar.css";

function toDateStr(d) {
  var z  = n =>  ('0' + n).slice(-2);

  return d.getFullYear() + ''
         + z(d.getMonth()+1) + ''
         + z(d.getDate()); 
}

function getCalendarSrc(props) {
  var isMobile = window.matchMedia("only screen and (max-width: 760px)")
    .matches;
  var mode = isMobile ? "AGENDA" : "WEEK";
  var extraOptions = isMobile
    ? "&showPrint=0&showTz=0&showTabs=0"
    : "&showPrint=1&showTz=1&showTabs=1";
  var srcUrl =
    "https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23f2f2f2&ctz=America%2FDenver&" +
    props.src +
    "&showTitle=0&showNav=1&showCalendars=1&showDate=1&mode=" +
    mode +
    extraOptions;
  var dateStr = toDateStr(props.date);
  return (
    srcUrl +
    "&dates=" +
    dateStr +
    "%2F" +
    dateStr +
    // A hacky way to enable rerendering of the iframe.
    "&refreshId=" +
    props.refreshId
  );
}

function GoogleCalendar(props) {
  var calendarSrc = getCalendarSrc(props);

  return (
    <div className="Calendar">
      <iframe
        frameBorder="0"
        src={calendarSrc}
        id="wildsong_calendar"
        title="calendar"
        scrolling="no"
        style={{ borderWidth: 0 }}
        width="800"
      ></iframe>
    </div>
  );
}

GoogleCalendar.propTypes = {
  src: PropTypes.string.isRequired,
  date: PropTypes.instanceOf(Date).isRequired,
  refreshId: PropTypes.number,
};

export default GoogleCalendar;
