import React from "react";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import Spinner from "react-bootstrap/Spinner";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

function toggleBookable(horse) { }

function listHorses(horses) {
  if (horses) {
    return horses.map((horse) => {
      return (
        <tr key={horse["id"]}>
          <td>
            <Link to={"/horses/" + horse["id"]}>{horse["name"]}</Link>
          </td>
          <td>{horse && horse["herd"] && horse["herd"]["name"]}</td>
          <td style={{ textAlign: "center" }}>
            {horse && horse["calendarResourceEmail"] && (
              <a
                href={
                  "https://calendar.google.com/calendar/embed?src=" +
                  horse["calendarResourceEmail"]
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="material-icons align-middle">calendar_today</i>
              </a>
            )}
          </td>
          <td style={{ textAlign: "center" }}>
            {horse && (
              <input
                type="checkbox"
                checked={horse["bookable"]}
                onClick={toggleBookable(horse)}
              />
            )}
          </td>
          <td style={{ textAlign: "center" }}>
            {horse.bookable ? "All Practitioners" : horse && horse.bookableBy && horse.bookableBy.map(contact => <>{contact.firstName} {contact.lastName}<br /></>)}
          </td>
        </tr>
      );
    });
  } else {
    return (
      <tr>
        <td style={{ textAlign: "center" }} colSpan={6}>
          <Spinner animation="border" variant="primary" /> Loading...
        </td>
      </tr>
    );
  }
}

function ManageHorses({ me }) {
  return (
    <>
      <Modal.Header>
        <Modal.Title className="align-middle">My Horses</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th>Name</th>
              <th>Herd</th>
              <th style={{ textAlign: "center" }}>Calendar</th>
              <th style={{ textAlign: "center" }}>Bookable?</th>
              <th style={{ textAlign: "center" }}>Bookable By</th>
            </tr>
          </thead>
          <tbody>{listHorses(me.horses)}</tbody>
        </Table>
      </Modal.Body>
    </>
  );
}

ManageHorses.propTypes = {
  me: PropTypes.object,
};

export default ManageHorses;
