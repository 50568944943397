import Table from "react-bootstrap/esm/Table";
import { millisToHours } from "./dates";

function calculateFeePerHorse(horse, durationHours) {
  // $10 per horse up to one hour, $14 if greater than one hour, or the horse session fee if set.
  return (
    (horse && horse.sessionFee) ||
    (!durationHours || durationHours <= 1 ? 10 : 14)
  );
}

function calculateMonthlyFee(me) {
  // No longer doing separately monthly fees - so always return 0
  return 0;
}

function calculateSessionFee(groupSize, me, durationHours) {
  durationHours = Math.ceil(durationHours * 2) / 2; // Round up to nearest 1/2 hour

  if (groupSize && groupSize < 0) {
    // Yes, it's a hack, but it works for now - use negative value for group size to 
    // indicate there is no session fee.
    return 0;
  }

  if (groupSize && groupSize > 2) {
    // $6 / group member / hr
    return 6 * groupSize * durationHours;
  }

  // $18/hr for non group sessions
  return 18 * durationHours;
}

function calculateHorseFee(horse, durationHours, me) {
  let feePerHorse = calculateFeePerHorse(horse, durationHours);
  return (me && horse.owners.includes(me.id)) ||
    horse.responseStatus === "declined"
    ? 0
    : feePerHorse;
}

function calculateEventFee(sessionFee, horses, durationHours, me) {
  if (!horses) {
    horses = [];
  }
  return horses
    ? horses.reduce((sum, horse) => {
      return sum + calculateHorseFee(horse, durationHours, me);
    }, sessionFee)
    : sessionFee;
}

export const EVENT_SUMMARY_REGEX =
  /^(?<type>(Practitioner|Additional) (?:Session|Group|Resources))( of (?<size>\d+))?(?: - (?<summary>.*))? \([^(].*\)$/;

export function getGroupSize(eventSummary) {
  const summaryMatch = eventSummary && eventSummary.match(EVENT_SUMMARY_REGEX);
  return (
    (summaryMatch &&
      summaryMatch.groups &&
      parseInt(summaryMatch.groups.size)) ||
    (summaryMatch &&
      summaryMatch.groups &&
      summaryMatch.groups.type === "Additional Resources" &&
      -1) ||
    0
  );
}

export function getSessionType(eventSummary) {
  const summaryMatch = eventSummary && eventSummary.match(EVENT_SUMMARY_REGEX);
  return (
    (summaryMatch && summaryMatch.groups && summaryMatch.groups.type) || ""
  );
}

export function eventFees(event, me, resourcesByEmail) {
  let horses =
    event &&
    event.resources &&
    event.resources
      .filter((r) => r.type === "Horse")
      .map((r) => {
        let resource = (resourcesByEmail && resourcesByEmail[r.email]) || r;
        return {
          sessionFee: resource.horse && resource.horse.sessionFee,
          name: resource.name,
          responseStatus: r.responseStatus,
          owners:
            resource.horse && resource.horse.owners
              ? resource.horse.owners.map((owner) => owner.id)
              : [],
        };
      });
  let durationHours = millisToHours(
    new Date(event.endDateTime) - new Date(event.startDateTime)
  );
  durationHours = Math.ceil(durationHours * 2) / 2; // Round up to nearest 1/2 hour

  return calculateEventFee(
    calculateSessionFee(getGroupSize(event.summary), me, durationHours),
    horses,
    durationHours,
    me
  );
}

export function eventsLineItemsByMonth(events, me, resourcesByEmail) {
  return events.reduce((allLineItems, event) => {
    let month = new Date(event.startDateTime).toLocaleString("default", {
      month: "long",
    });
    let lineItems = eventLineItems(event, me, resourcesByEmail);

    if (!allLineItems[month]) {
      allLineItems[month] = {
        fee: calculateMonthlyFee(me),
        group: { ct: 0, fee: 0.0 },
        session: { ct: 0, fee: 0.0 },
        horses: {},
        total: 0.0,
      };
      allLineItems[month].total += allLineItems[month].fee;
    }

    allLineItems[month].session.ct += lineItems.session.ct * lineItems.session.hours;
    allLineItems[month].session.fee += lineItems.session.fee;
    allLineItems[month].total += lineItems.session.fee;

    allLineItems[month].group.ct += lineItems.group.ct * lineItems.group.hours;
    allLineItems[month].group.fee += lineItems.group.fee;
    allLineItems[month].total += lineItems.group.fee;

    for (const horse in lineItems.horses) {
      if (!allLineItems[month].horses[horse]) {
        allLineItems[month].horses[horse] = lineItems.horses[horse];
      } else {
        allLineItems[month].horses[horse].ct += lineItems.horses[horse].ct;
        allLineItems[month].horses[horse].fees += lineItems.horses[horse].fees;
      }
      allLineItems[month].total += lineItems.horses[horse].fees;
    }

    return allLineItems;
  }, {});
}

function getMonthFromString(mon) {
  return new Date(Date.parse(mon + " 1, 2022")).getMonth();
}

export function eventMonthSummaryTable(month, summary) {
  let currentMonth = new Date().getMonth();
  let thisMonth = getMonthFromString(month);

  let title = month + " Summary";
  if (
    thisMonth === currentMonth - 1 ||
    (thisMonth === 11 && currentMonth === 0)
  ) {
    title += " (Complete)";
  } else {
    title += " (Partial)";
  }

  return (
    <>
      <h3>{title}</h3>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Product/Service</th>
            <th>Quantity</th>
            <th className="hide-600">Rate</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          {summary.fee !== 0 && (
            <tr>
              <td>Monthly Fee</td>
              <td>1</td>
              <td className="hide-600">${summary.fee.toFixed(2)}</td>
              <td>${summary.fee.toFixed(2)}</td>
            </tr>
          )}
          {summary.session.ct !== 0 && (
            <tr>
              <td>Session Fee</td>
              <td>{summary.session.ct}</td>
              <td className="hide-600">
                ${(summary.session.fee / summary.session.ct).toFixed(2)}
              </td>
              <td>${summary.session.fee.toFixed(2)}</td>
            </tr>
          )}
          {summary.group.ct !== 0 && (
            <tr>
              <td>Group Fee (per participant-hour)</td>
              <td>{summary.group.ct}</td>
              <td className="hide-600">
                ${(summary.group.fee / summary.group.ct).toFixed(2)}
              </td>
              <td>${summary.group.fee.toFixed(2)}</td>
            </tr>
          )}
          {Object.keys(summary.horses).map((horse) => {
            return (
              <tr>
                <td>Horse Work: {horse}</td>
                <td>{summary.horses[horse].ct}</td>
                <td className="hide-600">
                  $
                  {(
                    summary.horses[horse].fees / summary.horses[horse].ct
                  ).toFixed(2)}
                </td>
                <td>${summary.horses[horse].fees.toFixed(2)}</td>
              </tr>
            );
          })}
          <tr>
            <th>TOTAL</th>
            <th></th>
            <th className="hide-600"></th>
            <th>${summary.total.toFixed(2)}</th>
          </tr>
        </tbody>
      </Table>
    </>
  );
}

function eventLineItems(event, me, resourcesByEmail) {
  let horses =
    event &&
    event.resources &&
    event.resources
      .filter((r) => r.type === "Horse")
      .map((r) => {
        let resource = (resourcesByEmail && resourcesByEmail[r.email]) || r;
        return {
          sessionFee: resource.horse && resource.horse.sessionFee,
          name: resource.name,
          responseStatus: r.responseStatus,
          owners:
            resource.horse && resource.horse.owners
              ? resource.horse.owners.map((owner) => owner.id)
              : [],
        };
      });
  let durationHours = millisToHours(
    new Date(event.endDateTime) - new Date(event.startDateTime)
  );
  durationHours = Math.ceil(durationHours * 2) / 2; // Round up to nearest 1/2 hour
  let horseLabel = durationHours <= 1 ? " (1 hour or less)" : " (over 1 hour)";

  let groupSize = getGroupSize(event.summary);
  let sessionFee = calculateSessionFee(groupSize, me, durationHours);
  let fees =
    groupSize > 2
      ? {
        group: {
          ct: groupSize,
          hours: durationHours,
          fee: sessionFee,
        },
        session: { ct: 0, hours: 0, fee: 0 },
        horses: {},
      }
      :
      sessionFee > 0 ?
        {
          group: { ct: 0, hours: 0, fee: 0 },
          session: { ct: 1, hours: durationHours, fee: calculateSessionFee(groupSize, me, durationHours) },
          horses: {},
        }
        :
        {
          group: { ct: 0, hours: 0, fee: 0 },
          session: { ct: 0, hours: 0, fee: 0 },
          horses: {},
        };

  return horses.reduce((fees, horse) => {
    if (horse.responseStatus === "declined") {
      return fees;
    }
    fees.horses[horse.name + horseLabel] = {
      ct: 1,
      hours: durationHours,
      fees: calculateHorseFee(horse, durationHours, me),
    };
    return fees;
  }, fees);
}

export function eventCostTable(groupSize, resources, durationHours, me) {
  let horses =
    resources &&
    resources
      .filter((r) => r.resource.type === "Horse")
      .map((r) => {
        return {
          sessionFee: r.resource.horse && r.resource.horse.sessionFee,
          name: r.resource.name,
          responseStatus: r.resource.responseStatus,
          owners:
            r.resource.horse && r.resource.horse.owners
              ? r.resource.horse.owners.map((owner) => owner.id)
              : [],
        };
      });

  let sessionFee = calculateSessionFee(groupSize, me, durationHours);

  if (horses && horses.length === 0 && sessionFee === 0) {
    return "";
  }

  let total = calculateEventFee(
    sessionFee,
    horses,
    durationHours,
    me
  );
  let horseLabel = durationHours <= 1 ? " (1 hour or less)" : " (over 1 hour)";

  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Product/Service</th>
          <th>Amount</th>
        </tr>
      </thead>
      <tbody>
        {groupSize > 2 && sessionFee !== 0 && (
          <tr>
            <td>Group Fee ($6 per participant)</td>
            <td>${sessionFee.toFixed(2)}</td>
          </tr>
        )}
        {groupSize <= 2 && sessionFee !== 0 && (
          <tr>
            <td>Session Fee</td>
            <td>${sessionFee.toFixed(2)}</td>
          </tr>
        )}
        {horses &&
          horses.map((horse) => {
            return (
              <tr key={horse.name}>
                <td>Horse Work: {horse.name + horseLabel}</td>
                <td>
                  ${calculateHorseFee(horse, durationHours, me).toFixed(2)}
                </td>
              </tr>
            );
          })}
        <tr>
          <th>TOTAL</th>
          <th>${total.toFixed(2)}</th>
        </tr>
      </tbody>
    </Table>
  );
}
