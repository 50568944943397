import React, { useState } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Calendar from "./Calendar";
import Alert from "react-bootstrap/Alert";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/esm/Container";
import CloseButton from "react-bootstrap/esm/CloseButton";
import Navbar from "react-bootstrap/Navbar";
import Spinner from "react-bootstrap/Spinner";
import GoogleAuth from "./google/GoogleAuth";
import ManageHorses from "./horses/ManageHorses";
import MyHorses from "./horses/MyHorses";
import Horse from "./horses/Horse";
import Practitioner from "./practitioners/Practitioner";
import Practitioners from "./practitioners/Practitioners";
import { useEvents, useResources, useContact, useContacts } from "./utils/hooks";

function App() {
  const [googleUser, setGoogleUser] = useState(null);
  const me = useContact(googleUser, "me");
  const resources = useResources(googleUser, me);
  const contacts = useContacts(googleUser, me);
  const [impersonate, setImpersonate] = useState(null);
  const [events, fetchEvents, doneLoadingFuture, doneLoadingPast] = useEvents(googleUser, impersonate);
  const horses = ((me && me.horses) || []).concat(
    (resources &&
      resources["hydra:member"] &&
      resources["hydra:member"]
        .filter((resource) => resource.type === "Horse" && resource.horse)
        .map((resource) => resource.horse)) ||
    []
  );

  return (
    <Router>
      <Navbar bg="light" expand="lg">
        <Container fluid>
          <Navbar.Brand href="/">
            <img
              height="60"
              src="/logo-horiz.png"
              alt="Wildsong Ranch"
              title="Wildsong Ranch"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Navbar.Text>
                <Link to="/">
                  <i className="material-icons align-middle">schedule</i>
                  <span className="ps-1 align-middle">{me && me.roles && me.roles.includes && me.roles.includes("ROLE_PRACTITIONER") && "My "}Schedule</span>
                </Link>
              </Navbar.Text>
              {googleUser && !me && (
                <Navbar.Text>
                  <span className="ps-1 align-middle">
                    <Spinner animation="border" variant="primary" />
                  </span>
                </Navbar.Text>
              )}
              {me && me.horses && me.horses.length > 0 && (
                <Navbar.Text>
                  <Link to="/horses">
                    <i className="material-icons align-middle">view_list</i>
                    <span className="ps-1 align-middle">My Horses</span>
                  </Link>
                </Navbar.Text>
              )}
              {me && me.roles && me.roles.includes && me.roles.includes("ROLE_ADMIN") && (
                <>
                  <Navbar.Text>
                    <Link to="/horses/admin">
                      <i className="material-icons align-middle">
                        admin_panel_settings
                      </i>
                      <span className="ps-1 align-middle">Manage Horses</span>
                    </Link>
                  </Navbar.Text>
                  <Navbar.Text>
                    <Link to="/practitioners">
                      <i className="material-icons align-middle">
                        supervised_user_circle
                      </i>
                      <span className="ps-1 align-middle">Practitioners</span>
                    </Link>
                  </Navbar.Text>
                </>
              )}
            </Nav>
            <GoogleAuth
              onLogin={setGoogleUser}
              onLogout={() => setGoogleUser(null)}
            ></GoogleAuth>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {impersonate && <Alert variant='warning'>
        impersonating
        {' ' + impersonate.firstName + ' ' + impersonate.lastName}
        &nbsp;
        <CloseButton onClick={() => setImpersonate(null)} />
      </Alert>
      }
      <Switch>
        <Route path="/horses/admin">
          {me && <ManageHorses me={me} resources={resources} />}
        </Route>
        <Route path="/horses/:id">
          {horses && horses.length > 0 && (
            <Horse me={me} googleUser={googleUser} horses={horses} />
          )}
        </Route>
        <Route path="/horses">{me && <MyHorses me={me} />}</Route>
        <Route path="/practitioners/:id">
          {me && <Practitioner me={me} googleUser={googleUser} contacts={contacts} />}
        </Route>
        <Route path="/practitioners">
          {me && <Practitioners me={me}
            googleUser={googleUser} impersonate={impersonate} setImpersonate={setImpersonate} contacts={contacts} />}
        </Route>
        <Route path="/">
          {me && me.roles && me.roles.includes && me.roles.includes("ROLE_PRACTITIONER") && (
            <Navbar bg="dark" variant="dark">
              <Container fluid className="ms-4">
                <Navbar.Text>
                  <Link className="light" to="/?create">
                    <i className="material-icons align-middle">
                      add_circle_outline
                    </i>
                    <span className="ps-1 align-middle">New Session</span>
                  </Link>
                </Navbar.Text>
              </Container>
            </Navbar>
          )}
          <Calendar
            googleUser={googleUser}
            resources={resources}
            me={me}
            events={events}
            doneLoadingFuture={doneLoadingFuture}
            doneLoadingPast={doneLoadingPast}
            fetchEvents={fetchEvents}
            impersonate={impersonate}
          />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
