import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import PropTypes from "prop-types";
import EventForm from "./EventForm";
import DraggableModalDialog from "../utils/DraggableModalDialog";
import fetch from "../utils/fetch";

function CreateEvent(props) {
  const [saving, setSaving] = useState(false);

  let handleSubmit = (results) => {
    setSaving(true);
    var newEvent = { ...results, id: "new" };
    fetch("events", {
      user: props.googleUser,
      method: "POST",
      body: JSON.stringify(newEvent),
    }, props.impersonate)
      .then((response) => response.json())
      .then((eventResponse) => {
        props.onCreate && props.onCreate(eventResponse);
        props.onHide();
        setSaving(false);
      })
      .catch((error) => {
        window.alert("Failed to create event:\n\n" + error);
        setSaving(false);
      });
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      dialogAs={DraggableModalDialog}
      size="lg"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="align-middle">New Session</Modal.Title>
        <OverlayTrigger
          placement="right"
          overlay={
            <Popover>
              <Popover.Header as="h3">New Sessions</Popover.Header>
              <Popover.Body>
                Sessions can be created using the scheduler if the{" "}
                <strong>start date</strong> of the session is at least{" "}
                <strong>1 day</strong> out.
              </Popover.Body>
            </Popover>
          }
        >
          <i className="material-icons align-middle help ps-1">help_outline</i>
        </OverlayTrigger>
      </Modal.Header>
      <Modal.Body>
        <EventForm
          me={props.me}
          saving={saving}
          resources={props.resources}
          resourcesByEmail={props.resourcesByEmail}
          onSubmit={handleSubmit}
          onDateChange={props.onDateChange}
          impersonate={props.impersonate}
        />
      </Modal.Body>
    </Modal>
  );
}

CreateEvent.propTypes = {
  googleUser: PropTypes.object,
  me: PropTypes.object,
  resources: PropTypes.object,
  resourcesByEmail: PropTypes.object,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onCreate: PropTypes.func,
  onDateChange: PropTypes.func,
  impersonate: PropTypes.object,
};

export default CreateEvent;
