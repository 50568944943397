import React, { useState } from "react";
import _ from "lodash";
import Modal from "react-bootstrap/Modal";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import PropTypes from "prop-types";
import EventForm from "./EventForm";
import Form from "react-bootstrap/Form";
import DraggableModalDialog from "../utils/DraggableModalDialog";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  addDays,
  diffDays
} from "../utils/dates";
import fetch from "../utils/fetch";
import format from "date-fns/format";

function EditEvent(props) {
  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [deleteConfirmation, showDeleteConfirmation] = useState(false);
  const [submitConfirmation, showSubmitConfirmation] = useState(false);
  const [eventToSave, setEventToSave] = useState(props.event);
  const [recurrenceChoice, setRecurrenceChoice] = useState("thisEventOnly");

  let handleSubmit = (updatedEvent) => {
    setEventToSave(updatedEvent);
    setSaving(true);
    if (updatedEvent.recurrence && updatedEvent.recurringEventId) {
      setRecurrenceChoice("thisEventOnly");
      showSubmitConfirmation(true);
    } else {
      updateEvent(updatedEvent);
    }
  }

  let confirmSubmit = (updatedEvent = null) => {
    showSubmitConfirmation(false);
    updatedEvent = updatedEvent ? updatedEvent : eventToSave;

    if (props.event.recurringEventId) {
      // Handle editing of existing recurring events properly.
      if (recurrenceChoice === "thisEventOnly") {
        updatedEvent.recurrence = [];
        updateEvent(updatedEvent);
      } else if (recurrenceChoice === "thisAndFollowingEvents") {
        // If we are editing the first event in the recurrence, then just update
        // the entire recurrence.
        if (props.event.recurringStartDate === props.event.startDateTime) {
          updatedEvent.id = props.event.recurringEventId;
          updateEvent(updatedEvent);
          return;
        }

        // If we are editing any event other than the first event in the recurrence,
        // then we need to update the original recurrence to stop before this update
        // and then create a new recurrence moving forward.

        // Update the original recurring event to stop recurring before this event.
        let originalRecurringEvent = _.clone(props.event);
        originalRecurringEvent.id = props.event.recurringEventId;
        originalRecurringEvent.attendees = []; // filled in on server
        let recurringStartDate = new Date(props.event.recurringStartDate);
        let originalStartDateTime = new Date(props.event.startDateTime);
        originalRecurringEvent.startDateTime = new Date(
          recurringStartDate.getFullYear(),
          recurringStartDate.getMonth(),
          recurringStartDate.getDate(),
          originalStartDateTime.getHours(),
          originalStartDateTime.getMinutes(),
          originalStartDateTime.getSeconds(),
          originalStartDateTime.getMilliseconds());

        let originalEndDateTime = new Date(props.event.endDateTime);
        originalRecurringEvent.endDateTime = new Date(
          recurringStartDate.getFullYear(),
          recurringStartDate.getMonth(),
          recurringStartDate.getDate(),
          originalEndDateTime.getHours(),
          originalEndDateTime.getMinutes(),
          originalEndDateTime.getSeconds(),
          originalEndDateTime.getMilliseconds());

        // Stop previous recurrence the day before the target event.
        let recurrence = originalRecurringEvent.recurrence[0];
        if (recurrence.indexOf(";UNTIL") > 0) {
          recurrence = recurrence.substring(0, recurrence.indexOf(";UNTIL"));
        }
        recurrence += ";UNTIL=" + format(
          addDays(updatedEvent.startDateTime, -1), "yyyyMMdd'T'000000'Z'");
        originalRecurringEvent.recurrence = [recurrence];

        // Update the original event with the new end date, then create a new event for
        // all future changes.
        updateEvent(originalRecurringEvent, () => { return createEvent(updatedEvent); });

        setRecurrenceChoice("thisEventOnly") // Reset
      }
    }
  };

  let updateEvent = (updatedEvent, extraWork) => {
    updatedEvent.viewedAs = undefined;
    return fetch("events/" + updatedEvent.id, {
      user: props.googleUser,
      method: "PUT",
      body: JSON.stringify(updatedEvent),
    }, props.impersonate)
      .then((response) => response.json())
      .then((eventResponse) => {
        if (extraWork) {
          extraWork();
        }
        props.onEdit && props.onEdit(eventResponse);
        props.onHide();
        setSaving(false);
        setDeleting(false);
        setRecurrenceChoice("thisEventOnly") // Reset
      })
      .catch((error) => {
        window.alert("Failed to edit event:\n\n" + error);
        setSaving(false);
        setDeleting(false);
        setRecurrenceChoice("thisEventOnly") // Reset
      });
  }

  let createEvent = async (event) => {
    var newEvent = { ...event, id: "new" };
    await fetch("events", {
      user: props.googleUser,
      method: "POST",
      body: JSON.stringify(newEvent),
    }, props.impersonate)
      .then((response) => response.json())
      .then((eventResponse) => {
        // Do nothing.
      })
      .catch((error) => {
        window.alert("Failed to edit recurring event:\n\n" + error);
      });
  }

  let cancelSubmit = () => {
    showSubmitConfirmation(false);
    setSaving(false);
    setRecurrenceChoice("thisEventOnly") // Reset
  };

  let handleRecurrenceChoice = (e) => {
    setRecurrenceChoice(e.target.value);
  };

  let handleDelete = () => {
    setDeleting(true);
    showDeleteConfirmation(true);
  };

  let deleteEvent = (eventId) => {
    fetch("events/" + eventId, {
      user: props.googleUser,
      method: "DELETE",
    }, props.impersonate)
      .then((deleteResponse) => {
        props.onDelete && props.onDelete(deleteResponse);
        props.onHide();
        setDeleting(false);
        setRecurrenceChoice("thisEventOnly") // Reset
      })
      .catch((error) => {
        window.alert("Failed to delete event:\n\n" + error);
        setDeleting(false);
        setRecurrenceChoice("thisEventOnly") // Reset
      });
  };

  let confirmDelete = () => {
    showDeleteConfirmation(false);

    if (!props.event.recurringEventId || recurrenceChoice === "thisEventOnly") {
      deleteEvent(props.event.id);
      return;
    } else if (recurrenceChoice === "thisAndFollowingEvents") {
      // If we are deleting the first event in the recurrence, then just delete
      // the entire recurrence.
      if (props.event.recurringStartDate === props.event.startDateTime) {
        deleteEvent(props.event.recurringEventId);
        return;
      }

      // If we are deleting any event other than the first event in the recurrence,
      // then we need to update the original recurrence to stop before this event.

      // Update the original recurring event to stop recurring before this event.
      let originalRecurringEvent = _.clone(props.event);
      originalRecurringEvent.id = props.event.recurringEventId;
      originalRecurringEvent.attendees = []; // filled in on server
      let recurringStartDate = new Date(props.event.recurringStartDate);
      let originalStartDateTime = new Date(props.event.startDateTime);
      originalRecurringEvent.startDateTime = new Date(
        recurringStartDate.getFullYear(),
        recurringStartDate.getMonth(),
        recurringStartDate.getDate(),
        originalStartDateTime.getHours(),
        originalStartDateTime.getMinutes(),
        originalStartDateTime.getSeconds(),
        originalStartDateTime.getMilliseconds());

      let originalEndDateTime = new Date(props.event.endDateTime);
      originalRecurringEvent.endDateTime = new Date(
        recurringStartDate.getFullYear(),
        recurringStartDate.getMonth(),
        recurringStartDate.getDate(),
        originalEndDateTime.getHours(),
        originalEndDateTime.getMinutes(),
        originalEndDateTime.getSeconds(),
        originalEndDateTime.getMilliseconds());

      // Stop previous recurrence the day before the target event.
      let recurrence = originalRecurringEvent.recurrence[0];
      if (recurrence.indexOf(";UNTIL") > 0) {
        recurrence = recurrence.substring(0, recurrence.indexOf(";UNTIL"));
      }
      recurrence += ";UNTIL=" + format(
        addDays(props.event.startDateTime, -1), "yyyyMMdd'T'000000'Z'");
      originalRecurringEvent.recurrence = [recurrence];

      updateEvent(originalRecurringEvent);
    }
  }

  let cancelDelete = () => {
    showDeleteConfirmation(false);
    setDeleting(false);
    setRecurrenceChoice("thisEventOnly") // Reset0
  };

  // Disable changing date / time if the event is today.
  let disableEditDateTime =
    diffDays(new Date(), new Date(props.event.startDateTime)) < 1;

  // Disable editing or deleting if the event happened more than a week ago.
  let disableEditDelete =
    diffDays(new Date(props.event.startDateTime), new Date()) > 7;

  return (
    <>
      <Modal
        show={props.show}
        onHide={props.onHide}
        dialogAs={DraggableModalDialog}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="align-middle">
            {(disableEditDelete && "View") || "Edit"} Session
          </Modal.Title>
          <OverlayTrigger
            placement="right"
            overlay={
              <Popover>
                <Popover.Header as="h3">Editing Sessions</Popover.Header>
                <Popover.Body>
                  Session dates/times can be edited online if the{" "}
                  <strong>start date</strong> of the event is at least{" "}
                  <strong>1 day</strong> out. Sessions may be cancelled, and / or
                  resources edited online up to a week after the Session. For all
                  other changes please contact{" "}
                  <a href="mailto:todd@wildsongranch.com">Todd</a> for
                  assistance.
                </Popover.Body>
              </Popover>
            }
          >
            <i className="material-icons align-middle help ps-1">
              help_outline
            </i>
          </OverlayTrigger>
        </Modal.Header>
        <Modal.Body>
          <EventForm
            me={props.me}
            event={props.event}
            saving={saving}
            deleting={deleting}
            resources={props.resources}
            resourcesByEmail={props.resourcesByEmail}
            onSubmit={handleSubmit}
            onDelete={handleDelete}
            onDateChange={props.onDateChange}
            disableEditDateTime={disableEditDateTime}
            disableEditDelete={disableEditDelete}
            impersonate={props.impersonate}
          />
        </Modal.Body>
      </Modal>
      <SweetAlert
        danger
        title="Are you sure?"
        onConfirm={confirmDelete}
        onCancel={cancelDelete}
        showCancel={true}
        show={deleteConfirmation}
      >
        This will permanently delete this session, and
        <br />
        notifications will be sent to horse owners.
        <div style={{ "text-align": "center" }}>
          <div style={{ "display": "inline-block", "text-align": "left" }}>
            <Form.Check
              value="thisEventOnly"
              type="radio"
              name="recurrenceChoice"
              aria-label="This session only"
              label="This session only"
              onChange={handleRecurrenceChoice}
              checked={recurrenceChoice === "thisEventOnly"}
            />
            <Form.Check
              value="thisAndFollowingEvents"
              type="radio"
              name="recurrenceChoice"
              aria-label="This and all following Sesssions"
              label="This and all following sessions"
              onChange={handleRecurrenceChoice}
              checked={recurrenceChoice === "thisAndFollowingEvents"}
            />
          </div>
        </div>

      </SweetAlert>
      <SweetAlert
        title="Edit recurring session"
        onConfirm={confirmSubmit}
        onCancel={cancelSubmit}
        showCancel={true}
        show={submitConfirmation}
      >
        <div style={{ "text-align": "center" }}>
          <div style={{ "display": "inline-block", "text-align": "left" }}>
            <Form.Check
              value="thisEventOnly"
              type="radio"
              name="recurrenceChoice"
              aria-label="This session only"
              label="This session only"
              onChange={handleRecurrenceChoice}
              checked={recurrenceChoice === "thisEventOnly"}
            />
            <Form.Check
              value="thisAndFollowingEvents"
              type="radio"
              name="recurrenceChoice"
              aria-label="This and all following Sesssions"
              label="This and all following sessions"
              onChange={handleRecurrenceChoice}
              checked={recurrenceChoice === "thisAndFollowingEvents"}
            />
          </div>
        </div>
      </SweetAlert>
    </>
  );
}

EditEvent.propTypes = {
  googleUser: PropTypes.object,
  me: PropTypes.object,
  resources: PropTypes.object,
  resourcesByEmail: PropTypes.object,
  show: PropTypes.bool.isRequired,
  event: PropTypes.object.isRequired,
  onHide: PropTypes.func.isRequired,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onDateChange: PropTypes.func,
  impersonate: PropTypes.object,
};

export default EditEvent;
