const MIME_TYPE = "application/ld+json";

const ENTRYPOINT =
  process.env.NODE_ENV === "development"
    ? "http://localhost:8000/api/"
    : window.location.origin +
      process.env.PUBLIC_URL.replace(/\/?$/, "/") +
      "api/";

function fetch(id, options = {}, impersonate = {}, queryParams = {}) {
  if ("undefined" === typeof options.headers) options.headers = new Headers();
  if (null === options.headers.get("Accept"))
    options.headers.set("Accept", MIME_TYPE);

  if (
    "undefined" !== options.body &&
    !(options.body instanceof FormData) &&
    null === options.headers.get("Content-Type")
  ) {
    options.headers.set("Content-Type", MIME_TYPE);
  }

  if (options.user) {
    // Refresh the auth token before attempting the request if it expires within 5 minutes.
    var oneMinute = 60000; // in milliseconds
    if (
      !options.alreadyRetried &&
      options.user.getAuthResponse().expires_at < Date.now() + 5 * oneMinute
    ) {
      options.alreadyRetried = true;
      return options.user.reloadAuthResponse().then(() => fetch(id, options));
    }

    options.headers.set(
      "X-Google-Auth-Token",
      options.user.getAuthResponse().id_token
    );
  }

  if (impersonate && impersonate.googleAccount) {
    queryParams['impersonate'] = impersonate.googleAccount;
  }

  if (Object.keys(queryParams).length >= 1) {
    id += '?';
  }

  for (var key in queryParams) {
    if (queryParams[key]) {
      id += key + "=" + queryParams[key] + "&"
    }
  }

  return global.fetch(new URL(id, ENTRYPOINT), options).then(response => {
    if (response.ok) return response;

    return response.json().then(
      json => {
        const error =
          json["hydra:description"] ||
          json["hydra:title"] ||
          json["message"] ||
          "An error occurred.";
        if (!json.violations) throw Error(error);

        let errors = { _error: error };
        json.violations.forEach(violation =>
          errors[violation.propertyPath]
            ? (errors[violation.propertyPath] +=
                "\n" + errors[violation.propertyPath])
            : (errors[violation.propertyPath] = violation.message)
        );

        throw new Error(errors);
      },
      () => {
        throw new Error(response.statusText || "An error occurred.");
      }
    );
  });
}

export default fetch;
