import React from "react";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import Spinner from "react-bootstrap/Spinner";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { usePractitioners } from "../utils/hooks";
import { humanReadableDuration, millisToHours } from "../utils/dates";

function listPractitioners(practitioners, isAdmin, impersonate, setImpersonate, contacts) {
  if (practitioners && contacts) {
    // Pull out contact data for each practioner.
    practitioners = practitioners.map((item, i) =>
      Object.assign({}, item, contacts.find((contact) => contact.googleAccount === item.email)
        || { id: item.email, googleAccount: item.email, firstName: item.email, lastName: '' }));

    // Sort by first name.
    practitioners = practitioners.sort((a, b) => a.firstName.localeCompare(b.firstName))
    return practitioners.map((practitioner) => {
      if (practitioner.email === 'scheduler@wildsongranch.com') return '';
      let lastLogin = practitioner && practitioner.lastLogin && Date.parse(practitioner.lastLogin);
      lastLogin = lastLogin && Date.now() - lastLogin;
      lastLogin = lastLogin && millisToHours(lastLogin) <= 1 ? "now" : "~" + humanReadableDuration(lastLogin, false) + " ago";
      return (
        <tr key={practitioner["id"]}>
          <td>
            <Link to={"/practitioners/" + practitioner.googleAccount}>
              <img className="ms-2 shadow border border-secondary"
                style={{ borderRadius: "50%" }}
                src={practitioner.profilePicture}
                height="30px" width="30px" />
              {practitioner.firstName + ' ' + practitioner.lastName}
            </Link>
          </td>
          <td>{practitioner && practitioner.googleAccount}</td>
          {isAdmin && 
          <>
          <td title={practitioner.lastLogin}>
              {lastLogin}
          </td>
          <td>
            <input
              type="checkbox"
              checked={impersonate && impersonate.googleAccount === practitioner.googleAccount}
              onClick={() => {
                (impersonate && impersonate.googleAccount === practitioner.googleAccount) ? setImpersonate(null) : setImpersonate(practitioner)
              }}
            />
          </td>
          </>}
        </tr>);
    });
  } else {
    return (
      <tr>
        <td style={{ textAlign: "center" }} colSpan={5}>
          <Spinner animation="border" variant="primary" /> Loading...
        </td>
      </tr>
    );
  }
}

function Practitioners({ googleUser, me, impersonate, setImpersonate, contacts }) {
  const practitioners = usePractitioners(googleUser, me);
  const isAdmin = me && me.roles && me.roles.includes && me.roles.includes("ROLE_ADMIN");
  return (
    <>
      <Modal.Header>
        <Modal.Title className="align-middle">Practitioners</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              {isAdmin && <th>Last Login</th>}
              {isAdmin && <th>Impersonate</th>}
            </tr>
          </thead>
          <tbody>{listPractitioners(practitioners, isAdmin, impersonate, setImpersonate, contacts)}</tbody>
        </Table>
      </Modal.Body>
    </>
  );
}

Practitioners.propTypes = {
  googleUser: PropTypes.object,
  me: PropTypes.object,
  impersonate: PropTypes.object,
  setImpersonate: PropTypes.func,
  contacts: PropTypes.array,
};

export default Practitioners;
