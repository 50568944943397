export function newTime(hours, minutes) {
  var date = new Date();
  date.setHours(hours, minutes, 0, 0);
  return date;
}

export function getToday() {
  var date = new Date();
  date.setHours(0, 0, 0, 0);
  return date;
}

export function asYYYYMMDD(date) {
  return date.toISOString().split('T')[0];
}

export function addMinutes(date, minutes) {
  return new Date(date.getTime() + minutes * 60 * 1000);
}

export function addHours(date, hours) {
  return addMinutes(date, hours * 60);
}

export function addDays(date, days) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export function addMonths(date, months) {
  var result = new Date(date);
  result.setMonth(result.getMonth() + months);
  return result;
}

export function firstDayOfMonth(date) {
  return new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0);
}

export function lastDayOfMonth(date) {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59);
}

export function millisToHours(milliseconds) {
  var minutes = Math.floor(milliseconds / 1000 / 60);
  return minutes / 60;
}

function roundHalf(num) {
  return Math.round(num*2)/2;
}

export function humanReadableDuration(milliseconds, parens=true) {
  var minutes = Math.floor(milliseconds / 1000 / 60);
  var hours = roundHalf(minutes / 60);
  var days = roundHalf(hours / 24);
  var weeks = roundHalf(days / 7);

  let duration = "";
  if (hours < 1) {
    duration = minutes + " mins";
  } else if (hours === 1) {
    duration = "1 hr";
  } else if (hours < 24 ) {
    duration = hours + " hrs";
  } else if (days < 7) {
    duration = days + " days";
  } else {
    duration = weeks + " weeks";
  }

  return parens ? "(" + duration + ")" : duration;
}

function treatAsUTC(date) {
  var result = new Date(date);
  result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
  return result;
}

const millisecondsPerDay = 24 * 60 * 60 * 1000;
export function diffDays(startDate, endDate) {
  return (
    Math.floor(treatAsUTC(endDate) / millisecondsPerDay) -
    Math.floor(treatAsUTC(startDate) / millisecondsPerDay)
  );
}
