import { useState, useEffect } from "react";
import { addDays, addMonths, asYYYYMMDD, firstDayOfMonth ,getToday } from "./dates";
import fetch from "./fetch";

export function useResources(user, me) {
  const [resources, setResources] = useState(null);

  useEffect(() => {
    if (user && me && me.roles.includes("ROLE_PRACTITIONER")) {
      fetch("resources", { user: user }).then((response) =>
        response.json().then(setResources)
      );
    } else {
      setResources(null);
    }
  }, [user, me]);

  return resources;
}

export function usePractitioners(user, me) {
  const [practitioners, setPractitioners] = useState(null);

  useEffect(() => {
    let getMembers = () => {
      window.gapi.client.directory.members
        .list({
          groupKey: "practitioners@wildsongranch.com",
        })
        .then(function (response) {
          setPractitioners(response.result.members);
        });
    };

    setPractitioners(null);
    if (me && me.roles.includes("ROLE_ADMIN")) {
      if (
        user &&
        !user
          .getGrantedScopes()
          .includes(
            "https://www.googleapis.com/auth/admin.directory.group.member.readonly"
          )
      ) {
        const options = new window.gapi.auth2.SigninOptionsBuilder();
        options.setScope(
          "https://www.googleapis.com/auth/admin.directory.group.member.readonly"
        );

        user.grant(options).then(function (success) {
          getMembers();
        });
      } else {
        // Admin User already has proper scopes
        getMembers();
      }
    }
  }, [me, user]);

  return practitioners;
}

/*
 * serial executes Promises sequentially.
 * @param {funcs} An array of funcs that return promises.
 * @example
 * const urls = ['/url1', '/url2', '/url3']
 * serial(urls.map(url => () => $.ajax(url)))
 *     .then(console.log.bind(console))
 */
const serial = funcs =>
  funcs.reduce((promise, func) =>
    promise.then(result => func().then(Array.prototype.concat.bind(result))), Promise.resolve([]))

export function useEvents(user, impersonate) {
  const [events, setEvents] = useState(null);
  const [doneLoadingFuture, setDoneLoadingFuture] = useState(false);
  const [doneLoadingPast, setDoneLoadingPast] = useState(false);

  let fetchEvents = () => {
    const abortController = new AbortController();

    setEvents(null);
    setDoneLoadingFuture(false);
    setDoneLoadingPast(false);

    if (user) {
      // Fetch the next 14 days first to load quickly, then the remaining events.
      const today = getToday();
      const dateSpans = [
        { from: today, to: addDays(today, 14) },
        { from: addDays(today, 14), to: addMonths(today, 2), future: true },
        { from: firstDayOfMonth(addMonths(today, -1)), to: today, past: true },
      ]

      serial(dateSpans.map(span => () =>
        fetch("events", { user: user, signal: abortController.signal }, impersonate, {
          from: asYYYYMMDD(span.from), to: asYYYYMMDD(span.to)
        }).then(
          response => response.json()
        ).then(
          json => {
            setEvents(e => e ? [...new Set(e.concat(json['hydra:member']))] : json['hydra:member'])
            if (span.future) {
              setDoneLoadingFuture(true);
            }
            if (span.past) {
              setDoneLoadingPast(true);
            }
          }).catch(e => { 
            if(e.name === "AbortError") {
              // We know it's been canceled!
            } else {
              console.error(e);
            }
           })
      ));
    }
    return function cancel() {
      abortController.abort()
    }
  };

  useEffect(fetchEvents, [user, impersonate]);

  return [events, fetchEvents, doneLoadingFuture, doneLoadingPast];
}

export function useContact(user, id) {
  const [contact, setContact] = useState(null);

  useEffect(() => {
    if (user) {
      fetch("contacts/" + id, { user: user }).then((response) =>
        response.json().then(setContact)
      );
    } else {
      setContact(null);
    }
  }, [user, id]);

  return contact;
}

export function useContacts(user, me) {
  const [contacts, setContacts] = useState(null);

  useEffect(() => {
    if (user && me && me.roles.includes("ROLE_ADMIN")) {
      fetch("contacts", { user: user }).then((response) =>
        response.json().then((result) => setContacts(result['hydra:member']))
      );
    } else {
      setContacts(null);
    }
  }, [user, me]);

  return contacts;
}
