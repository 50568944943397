import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Spinner from "react-bootstrap/Spinner";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import format from "date-fns/format";
import { firstDayOfMonth, lastDayOfMonth } from "../utils/dates";

const truncate = (input) =>
  input.length > 30 ? `${input.substring(0, 30)}...` : input;

function responseStatus(event, calendarResourceEmail) {
  let attendee = event && event["attendees"] && event["attendees"].find(attendee => attendee.email === calendarResourceEmail);
  return (attendee && attendee["responseStatus"]) || "unknown";
}

function listEvents(events, calendarResourceEmail) {
  if (events) {
    if (events.length === 0) {
      return (
        <tr>
          <td style={{ textAlign: "center" }} colSpan={4}>
            No Bookings Found
          </td>
        </tr>
      );
    }
    return events.map((event) => {
      let startDateTime = new Date(event["start"]["dateTime"] || event["start"]["date"]);
      let endDateTime = new Date(event["end"]["dateTime"] || event["end"]["date"]);
      return (
        <tr key={event["id"]} className={responseStatus(event, calendarResourceEmail) === "declined" ?
        "declined" : ""}>
          <td className="hide-600">
            {format(startDateTime, "yyyy/M/d (eee)")}
          </td>
          <td className="show-600">
            {format(startDateTime, "M/d")}
          </td>
          <td className="hide-600">
            {format(startDateTime, "h:mm aa") +
              " - " +
              format(endDateTime, "h:mm aa")}
          </td>
          <td>
            <a
              href={event["htmlLink"]}
              target="_blank"
              rel="noopener noreferrer"
              title={event["summary"]}
            >
              {(event["summary"] && truncate(event["summary"])) ||
                (event["creator"] && event["creator"]["email"])}
            </a>
          </td>
          <td
            dangerouslySetInnerHTML={{
              __html:
                event["description"] &&
                ((event["description"].includes("Scheduled by") &&
                  event["description"]) ||
                  event["description"].split("\n").slice(1, 2).join("\n")),
            }}
          />
        </tr>
      );
    });
  } else {
    return (
      <tr>
        <td style={{ textAlign: "center" }} colSpan={5}>
          <Spinner animation="border" variant="primary" /> Loading...
        </td>
      </tr>
    );
  }
}

function Horse({ me, googleUser, horses }) {
  const [events, setEvents] = useState(null);
  const [date, setDate] = useState(new Date());

  let { id } = useParams();
  let horse = horses.find((horse) => horse.id === parseInt(id));

  useEffect(() => {
    let getEvents = () => {
      window.gapi.client.calendar.events
        .list({
          calendarId: horse.calendarResourceEmail,
          timeMin: firstDayOfMonth(date).toISOString(),
          timeMax: lastDayOfMonth(date).toISOString(),
          showDeleted: false,
          singleEvents: true,
          orderBy: "startTime",
        })
        .then(function (response) {
          setEvents(response.result.items);
        });
    };

    setEvents(null);
    if (horse && horse.calendarResourceEmail) {
      if (me && me.roles.includes("ROLE_ADMIN")) {
        if (
          googleUser &&
          !googleUser
            .getGrantedScopes()
            .includes("https://www.googleapis.com/auth/calendar.readonly")
        ) {
          const options = new window.gapi.auth2.SigninOptionsBuilder();
          options.setScope("https://www.googleapis.com/auth/calendar.readonly");

          googleUser.grant(options).then(
            function (success) {
              getEvents();
            },
            function (fail) {
              var token = window.gapi.client.getToken();
              window.gapi.client.setToken(null);
              getEvents();
              window.gapi.client.setToken(token);
            }
          );
        } else {
          // Admin User with proper scopes.
          getEvents();
        }
      } else {
        // Non-admin user, request w/out token.
        var token = window.gapi.client.getToken();
        window.gapi.client.setToken(null);
        getEvents();
        window.gapi.client.setToken(token);
      }
    }
  }, [me, googleUser, date, horse]);

  if (!horse) {
    return "Not Found";
  }

  return (
    <Container className="Horse">
      <h2>
        {horse && horse["calendarResourceEmail"] && (
          <a
            href={
              "https://calendar.google.com/calendar/embed?src=" +
              horse["calendarResourceEmail"]
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="material-icons align-middle">calendar_today</i>
          </a>
        )}{" "}
        <span className="align-middle">{horse.name}</span>
      </h2>
      <h3>
        <a
          href="javascript:undefined"
          onClick={() => setDate(new Date(date.setMonth(date.getMonth() - 1)))}
        >
          <i className="material-icons align-middle">arrow_back_ios</i>
        </a>
        <span className="align-middle">{format(date, "MMMM yyyy")}</span>
        <a
          href="javascript:undefined"
          onClick={() => setDate(new Date(date.setMonth(date.getMonth() + 1)))}
        >
          <i className="material-icons align-middle">arrow_forward_ios</i>
        </a>
      </h3>
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>Date</th>
            <th className="hide-600">Time</th>
            <th>Summary</th>
            <th>Details</th>
          </tr>
        </thead>
        <tbody>{listEvents(events, horse.calendarResourceEmail)}</tbody>
      </Table>
    </Container>
  );
}

Horse.propTypes = {
  googleUser: PropTypes.object,
  me: PropTypes.object,
  horses: PropTypes.array,
};

export default Horse;
