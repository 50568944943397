import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Spinner from "react-bootstrap/Spinner";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import format from "date-fns/format";
import { firstDayOfMonth, lastDayOfMonth } from "../utils/dates";

const truncate = (input) =>
  input.length > 30 ? `${input.substring(0, 30)}...` : input;

function listEvents(events) {
  if (events) {
    if (events.length === 0) {
      return (
        <tr>
          <td style={{ textAlign: "center" }} colSpan={5}>
            No Bookings Found
          </td>
        </tr>
      );
    }
    return events.map((event) => {
      return (
        <tr key={event["id"]}>
          <td className="hide-600">
            {format(new Date(event["start"]["dateTime"]), "yyyy/M/d (eee)")}
          </td>
          <td className="show-600">
            {format(new Date(event["start"]["dateTime"]), "M/d")}
          </td>
          <td className="hide-600">
            {format(new Date(event["start"]["dateTime"]), "h:mm aa") +
              " - " +
              format(new Date(event["end"]["dateTime"]), "h:mm aa")}
          </td>
          <td>
            <a
              href={event["htmlLink"]}
              target="_blank"
              rel="noopener noreferrer"
              title={event["summary"]}
            >
              {(event["summary"] && truncate(event["summary"])) ||
                (event["creator"] && event["creator"]["email"])}
            </a>
          </td>
          <td>
            {event["attendees"].map((attendee) => {
              if (!attendee["displayName"]) return "";
              return (
                <>
                  <span className={attendee["responseStatus"] === "declined" ?
                    "declined" : ""}>{attendee["displayName"]}</span> <br />
                </>
              );
            })}
          </td>
          <td
            dangerouslySetInnerHTML={{
              __html:
                event["description"] &&
                ((event["description"].includes("Scheduled by") &&
                  event["description"]) ||
                  event["description"].split("\n").slice(1, 2).join("\n")),
            }}
          />
        </tr>
      );
    });
  } else {
    return (
      <tr>
        <td style={{ textAlign: "center" }} colSpan={5}>
          <Spinner animation="border" variant="primary" /> Loading...
        </td>
      </tr>
    );
  }
}

function Practitioner({ me, googleUser, contacts }) {
  const [events, setEvents] = useState(null);
  const [date, setDate] = useState(new Date());

  let { id } = useParams();

  let practitioner = (contacts && contacts.find((contact) => contact.googleAccount === id)) ||
    { id: id, googleAccount: id, firstName: id, lastName: '' };

  useEffect(() => {
    let getEvents = () => {
      window.gapi.client.calendar.events
        .list({
          calendarId:
            "wildsongranch.com_0nen15oms3opmbnunbts8onkjo@group.calendar.google.com",
          timeMin: firstDayOfMonth(date).toISOString(),
          timeMax: lastDayOfMonth(date).toISOString(),
          showDeleted: false,
          singleEvents: true,
          q: id,
          orderBy: "startTime",
        })
        .then(function (response) {
          setEvents(response.result.items);
        });
    };

    setEvents(null);
    if (me && me.roles.includes("ROLE_ADMIN")) {
      if (
        googleUser &&
        !googleUser
          .getGrantedScopes()
          .includes("https://www.googleapis.com/auth/calendar.readonly")
      ) {
        const options = new window.gapi.auth2.SigninOptionsBuilder();
        options.setScope("https://www.googleapis.com/auth/calendar.readonly");

        googleUser.grant(options).then(
          function (success) {
            getEvents();
          },
          function (fail) {
            var token = window.gapi.client.getToken();
            window.gapi.client.setToken(null);
            getEvents();
            window.gapi.client.setToken(token);
          }
        );
      } else {
        // Admin User with proper scopes.
        getEvents();
      }
    }
  }, [id, me, googleUser, date]);

  return (
    <Container>
      <h2>
        <span className="align-middle">{practitioner.firstName} {practitioner.lastName}</span>
      </h2>
      <h3>
        <a
          href="javascript:undefined"
          onClick={() => setDate(new Date(date.setMonth(date.getMonth() - 1)))}
        >
          <i className="material-icons align-middle">arrow_back_ios</i>
        </a>
        <span className="align-middle">{format(date, "MMMM yyyy")}</span>
        <a
          href="javascript:undefined"
          onClick={() => setDate(new Date(date.setMonth(date.getMonth() + 1)))}
        >
          <i className="material-icons align-middle">arrow_forward_ios</i>
        </a>
      </h3>
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>Date</th>
            <th className="hide-600">Time</th>
            <th>Summary</th>
            <th>Resources</th>
            <th>Details</th>
          </tr>
        </thead>
        <tbody>{listEvents(events)}</tbody>
      </Table>
    </Container>
  );
}

Practitioner.propTypes = {
  googleUser: PropTypes.object,
  me: PropTypes.object,
  contacts: PropTypes.array,
};

export default Practitioner;
