export function ownerNames(horse) {
  if (horse && horse["owners"] && horse["owners"].length) {
    return horse["owners"].reduce((previous, current) => {
      return (previous += (previous.length ? " & " : "") + current.firstName);
    }, "");
  }

  return "no owner found";
}

export function ownerDetails(horse) {
  let formatOwner = (owner) => {
    return (
      (owner.profilePicture
        ? "<img className=\"ms-2 shadow border border-secondary\" " +
        "style=\"border-radius: 50% !important;\" " +
        "src=\"" + owner.profilePicture + "\" " +
        "height=\"60px\" width=\"60px\" /><br>"
        : "") +
      owner.firstName +
      " " +
      owner.lastName +
      (owner.primaryPhone
        ? "<br><a href='tel:" +
        owner.primaryPhone +
        "'>" +
        owner.primaryPhone +
        "</a>"
        : "") +
      (owner.secondaryPhone
        ? "<br><a href='tel:" +
        owner.secondaryPhone +
        "'>" +
        owner.secondaryPhone +
        "</a>"
        : "") +
      (owner.primaryEmail
        ? "<br><a href='mailto:" +
        owner.primaryEmail +
        "'>" +
        owner.primaryEmail +
        "</a>"
        : "") +
      (owner.secondaryEmail
        ? "<br><a href='mailto:" +
        owner.secondaryEmail +
        "'>" +
        owner.secondaryEmail +
        "</a>"
        : "")
    );
  };
  if (horse && horse["owners"] && horse["owners"].length) {
    return horse["owners"].reduce((previous, current) => {
      return previous + "<p>" + formatOwner(current) + "</p>";
    }, "");
  }

  return "";
}
