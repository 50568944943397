import React, { useState } from "react";
import PropTypes from "prop-types";
import Image from "react-bootstrap/Image";
import { GoogleLogin, GoogleLogout } from "react-google-login";

// TODO: Make these parameters.
const CLIENT_ID =
  "332402005024-jnu07ga9b3p97slp6r79eac26c57isnd.apps.googleusercontent.com";
const API_KEY = "AIzaSyCuyAJeQLvEjXBGmrr6Oav10E-34vDZySE";

function GoogleAuth(props) {
  const [loggedIn, setLoggedIn] = useState(false);
  const [username, setUserName] = useState("");
  const [userpic, setUserPic] = useState("");

  let loginSuccess = (googleUser) => {
    setLoggedIn(true);
    setUserName(googleUser.getBasicProfile().getGivenName());
    setUserPic(googleUser.getBasicProfile().getImageUrl());

    window.gapi.load("client", () => {
      window.gapi.client
        .init({
          apiKey: API_KEY,
          discoveryDocs: [
            "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
            "https://content.googleapis.com/discovery/v1/apis/admin/directory_v1/rest",
          ],
        })
        .then(() => {
          if (props.onLogin) {
            props.onLogin(googleUser);
          }
        });
    });
  };

  let loginFailure = (failureResponse) => {
    setLoggedIn(false);
    setUserName("");
    setUserPic("");
    console.log("failed to login: " + failureResponse);
  };

  let logoutSuccess = () => {
    setLoggedIn(false);
    setUserName("");
    setUserPic("");
    if (props.onLogout) {
      props.onLogout();
    }
  };

  let logoutFailure = (failureResponse) => {
    console.log("failed to logout: " + failureResponse);
  };

  return (
    <>
      {!loggedIn && (
        <>
          <GoogleLogin
            clientId="332402005024-jnu07ga9b3p97slp6r79eac26c57isnd.apps.googleusercontent.com"
            onSuccess={loginSuccess}
            onFailure={loginFailure}
            buttonText="Login "
            isSignedIn
            scope={"profile email"}
            cookiePolicy={"single_host_origin"}
            className="shadow"
          />
          <Image
            className="ms-2 shadow border border-secondary"
            src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
            roundedCircle
            height="40px"
            width="40px"
          />
        </>
      )}
      {loggedIn && (
        <>
          <GoogleLogout
            clientId={CLIENT_ID}
            onLogoutSuccess={logoutSuccess}
            onFailure={logoutFailure}
            buttonText="Logout"
            cookiePolicy="single_host_origin"
            className="shadow"
          />
          <Image
            className="ms-2 shadow border border-secondary"
            src={userpic}
            roundedCircle
            alt="profile photo"
            title={username}
            height="40px"
            width="40px"
          />
        </>
      )}
    </>
  );
}

GoogleAuth.propTypes = {
  onLogin: PropTypes.func,
  onLogout: PropTypes.func,
};

export default GoogleAuth;
