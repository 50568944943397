import React from "react";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import Spinner from "react-bootstrap/Spinner";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";
import { Link } from "react-router-dom";
import { ownerDetails, ownerNames } from "../utils/horses";

function toggleBookable(horse) { }

function listHorses(resources) {
  if (resources && resources["hydra:member"]) {
    return resources["hydra:member"].filter((resource) => {
      return resource["type"] === "Horse";
    }).sort((a, b) => {
      let horseA = a["horse"];
      let horseB = b["horse"];
      if (horseA["status"].localeCompare(horseB["status"]) === 0) {
        return horseA["name"].localeCompare(horseB["name"]);
      }
      return horseA["status"].localeCompare(horseB["status"]);
    }).map((resource) => {
      let horse = resource["horse"];
      let bookableBy = horse && horse.bookableBy && horse.bookableBy.filter(contact => contact.roles && contact.roles.includes("ROLE_PRACTITIONER"));
      return (
        <tr key={resource["id"]}>
          <td>
            <Link to={"/horses/" + horse["id"]}>{horse["name"]}</Link>
          </td>
          <td>
            <a
              data-for="tooltip"
              data-tip={horse && JSON.stringify(horse)}
              data-event="mouseenter click"
            >
              {horse && ownerNames(horse)}
            </a>
          </td>
          <td>{horse && horse["herd"] && horse["herd"]["name"]}</td>
          <td style={{ textAlign: "center" }}>
            {horse && horse["calendarResourceEmail"] && (
              <a
                href={
                  "https://calendar.google.com/calendar/embed?src=" +
                  horse["calendarResourceEmail"]
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="material-icons align-middle">calendar_today</i>
              </a>
            )}
          </td>
          <td style={{ textAlign: "center" }}>
            {horse && horse["status"]}
          </td>
          <td style={{ textAlign: "center" }}>
            {horse && (
              <input
                type="checkbox"
                checked={horse["bookable"]}
                onClick={toggleBookable(horse)}
              />
            )}
          </td>
          <td style={{ textAlign: "center" }}>
            {horse.bookable ? "All Practitioners" : bookableBy && bookableBy.map(contact => <>{contact.firstName} {contact.lastName}<br /></>)}
          </td>
        </tr>
      );
    });
  } else {
    return (
      <tr>
        <td style={{ textAlign: "center" }} colSpan={7}>
          <Spinner animation="border" variant="primary" /> Loading...
        </td>
      </tr>
    );
  }
}

function ManageHorses(props) {
  return (
    <>
      <Modal.Header>
        <Modal.Title className="align-middle">Manage Horses</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th>Name</th>
              <th>Owner(s)</th>
              <th>Herd</th>
              <th style={{ textAlign: "center" }}>Calendar</th>
              <th style={{ textAlign: "center" }}>Status</th>
              <th style={{ textAlign: "center" }}>Bookable?</th>
              <th style={{ textAlign: "center" }}>Bookable By</th>
            </tr>
          </thead>
          <tbody>{listHorses(props.resources)}</tbody>
        </Table>
        {props.resources && (
          <ReactTooltip
            id="tooltip"
            html={true}
            getContent={(horseJSON) => {
              let horse = JSON.parse(horseJSON);
              return ownerDetails(horse);
            }}
            effect="solid"
            place={"right"}
            type={"light"}
            clickable={true}
            border={true}
          />
        )}
      </Modal.Body>
    </>
  );
}

ManageHorses.propTypes = {
  me: PropTypes.object,
  resources: PropTypes.object,
};

export default ManageHorses;
